body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: whitesmoke;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root{
  --primary-color:whitesmoke;
  --secondary-color:#ffffff;
  --app-color:#f3690d;
  --app-danger:red;
  --app-small-fontsize:14px;
  --app-medium-fontsize:16px;
  --app-regular-fontsize:18px;
  --app-large-fontsize:24px;
  --app-largest-fontsize:28px;
  --app-extra-large-fontsize:35px;
}

.body{
    width:100%;
    margin-left: 0%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.body .body-wrapper{
    width: 90%;
}

@media(min-width:200px){
  .body .body-wrapper{
    width: 100%;
    
  }
}

@media(min-width:768px){
  .body .body-wrapper{
    width: 90%;
  }
}